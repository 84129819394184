@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .common-heading { 
        margin: 0 0 28px;
        h2, .h2 { 

        }

        .action {
            padding: 7px 0 0;
            a {
                .text {

                }

                .icon {
                    padding: 2px 0 0;
                }
            }
        }
    }

    .slider-heading { 
        margin: 0 0 30px;
    }
}
