.models-list-block { 
    display: inline-block;
    width: 100%;
    padding: 23px 0 80px;
    &.padding-to-margin {
        padding: 0;
        margin: 0 0 45px;
    }
	.models-list-block-holder { 
        display: inline-block;
        width: 100%;
		.heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 40px;
			h2, .h2 { 

			}
		}

		.models-list { 
            display: inline-block;
            width: 100%;
            &.tighter {
                .models-list-holder { 
                    .model-item { 
                        margin: 0 0 14.5px;
                        .model-item-holder { 
                            .name {
                                a {
                                    .name {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
			.models-list-holder { 
                display: inline-block;
                width: 100%;
                column-count: 6;
                column-gap: 31px;
				.model-item { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 20px;
					.model-item-holder { 
                        display: inline-block;
                        width: 100%;
                        position: relative;
                        box-sizing: border-box;
                        padding-left: 35px;
						.letter { 
                            position: absolute;
                            left: 0;
                            top: -2px;
                            font-size: 28px;
                            font-weight: 500;
                            line-height: 140%;
                            color: $brand-primary;
						}

						.name { 
                            display: inline-block;
                            width: 100%;
							a { 
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 140%;
                                color: $gray-base;
                                display: flex;
                                align-items: center;
                                &:hover {
                                    color: $brand-primary;
                                }
								.icon { 
                                    float: left;
                                    margin-right: 15px;
									img { 
                                        width: 40px;
                                        height: 40px;
                                        object-fit: contain;
									}
								}

								.name { 
                                    width: calc(100% - 40px - 15px);
                                    float: left;
								}
							}
						}
					}
				}
			}
		}

		.action { 
            margin: 20px 0 0;
			.btn { 
                padding: 14px 31px;
			}
		}
	}
}

@import "../../media/tablet/includes/index/models";
@import "../../media/mobile/includes/index/models";
