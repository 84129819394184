@media (max-width: $screen-lg) {
    // lg-md view
    .services-list { 
        margin: 0 0 33px;
        .services-list-holder { 
            .services-background {
                transform: translateY(-55%);
                img {
                    width: 591px;
                    height: 365px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .services-list { 
        margin: 0 0 33px;
        .services-list-holder { 
            .services-background {
                display: none;
            }

            .services-categories { 
                padding: 0;
                width: calc(100% + 10px);
                margin-left: -5px;
                .category-col { 
                    width: 33.333%;
                    padding: 0 5px;
                    margin: 0 0 27px;
                    .category-col-holder { 
                        .heading {

                        }

                        .services-links { 
                            ul {
                                li {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
