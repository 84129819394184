@media (max-width: $screen-lg) {
    // lg-md view
    .glass-form { 
        padding: 30px 0 30px;
        &.padding-to-margin {
            margin: 0 0 41px;
        }
        .glass-form-holder { 
            .heading { 

            }

            .selection-form { 
                padding: 29px 30px 24px;
                form {
                    width: calc(100% + 15px);
                    margin-left: -7.5px;
                    .fields { 
                        width: calc(100% - 300px);
                        padding: 0 7.5px;
                        .fields-holder { 
                            .form-group { 
                                width: calc(100% + 15px);
                                margin-left: -7.5px;
                                .form-col {
                                    padding: 0 7.5px;
                                }
                            }
                        }
                    }
    
                    .action-col {
                        width: 300px; 
                        padding: 0 7.5px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .glass-form { 
        &.padding-to-margin {
            margin: 0 0 24px;
        }
        .glass-form-holder { 
            .heading { 
                margin: 0 0 29px;
                h2, .h2 {

                }
            }

            .selection-form { 
                background-color: transparent;
                padding: 0;
                form {
                    width: calc(100% + 22px);
                    margin-left: -11px;
                    .fields { 
                        width: calc(100% - 67px);
                        padding: 0 11px;
                        .fields-holder { 
                            .form-group { 
                                width: calc(100% + 10px);
                                margin-left: -5px;
                                .form-col {  
                                    padding: 0 5px;
                                }
                            }
                        }
                    }

                    .action-col { 
                        width: 67px;
                        padding: 0 11px;
                        .action-col-holder { 
                            .action { 
                                .submit { 
                                    .btn {
                                        float: right;
                                        width: 45px;
                                        height: 45px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
