@media (max-width: $screen-sm) {
    // sm-view
    .catalog-filter { 
        .catalog-filter-holder { 
            form {
                .form-group { 
                    width: 100%;
                    margin: 0;
                    .form-col { 
                        padding: 0;
                        &.col-1 {
                            width: 100%;
                        }

                        &.col-2 {
                            width: 100%;
                        }
                        
                    }
                }

                .form-field { 
                    margin-bottom: 9.5px;
                }

                .action { 
                    .action-holder {
                        .submit { 
                            width: 100%;
                            float: left;
                            margin: 0 0 15px;
                            .btn {
                                
                            }
                        }
                    }
                }
                
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
