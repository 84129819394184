@media (max-width: $screen-sm) {
    // sm-view
    .glass-form { 
        padding: 20px 0 20px;
        .glass-form-holder { 
            .heading { 
            }

            .selection-form { 
                form {
                    width: 100%;
                    margin: 0;
                    .fields { 
                        width: 100%;
                        padding: 0;
                        .fields-holder { 
                            .form-group { 
                                width: 100%;
                                margin: 0;
                                .form-col { 
                                    width: 100%; 
                                    padding: 0;
                                    .form-field { 
                                        margin: 0 0 8px;
                                    }
                                }
                            }
                        }
                    }

                    .action-col { 
                        width: 100%;
                        padding: 0;
                        .action-col-holder { 
                            .action { 
                                margin: 21px 0 0;
                                .submit { 
                                    .btn {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
