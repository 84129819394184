.work-steps-block { 
    display: inline-block;
    width: 100%;
    background-color: $bg-secondary;
    padding: 63px 0 64px;
	.work-steps-block-holder { 
        display: inline-block;
        width: 100%;
		.heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 40px;
		}

		.steps { 
            display: flex;
            width: calc(100% + 30px);
            margin-left: -15px;
			.step { 
                width: 25%;
                height: auto;
                box-sizing: border-box;
                padding: 0 15px;
				.step-holder {
                    width: 100%;
                    height: 100%; 
                    background-color: white;
                    box-sizing: border-box;
                    padding: 25px 25px;
					.number { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 26px;
						.number-holder { 
                            float: left;
                            width: 44px;
                            height: 44px;
                            line-height: 44px;
                            background-color: $brand-primary;
                            color: white;
                            font-size: 28px;
                            text-align: center;
						}
					}

					.text { 
                        display: inline-block;
                        width: 100%;
						.text-holder { 
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 140%;                        
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/work_steps";
@import "../../media/mobile/includes/catalog/work_steps";
