.active-filters { 
    display: inline-block;
    width: 100%;
    margin: 0 0 22px;
	.active-filters-holder { 
        display: inline-block;
        width: 100%;
		.filter-item { 
            float: left;
            margin-right: 4px;
            margin-bottom: 4px;
			.filter-item-holder { 
                display: flex;
                align-items: center;
                background-color: $bg-secondary;
                padding: 7px 7px 7px;
				.name { 
                    float: left;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 12px;                 
				}

				.action { 
                    float: left;
                    margin-left: 7px;
                    width: 8px;
                    height: 7px;
					a { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        color: $gray-base;
                        &:hover {
                            color: $brand-primary;
                        }
						svg { 
                            width: 100%;
                            height: auto;
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/active_filters";
@import "../../media/mobile/includes/catalog/active_filters";
