@media (max-width: $screen-lg) {
    // lg-md view
    .services-block { 
        padding: 61px 0 61px;
        .services-block-holder {
            .services-grid { 
                .services-grid-holder { 
                    gap: 20px 20px; 
                    grid-template-columns: 49.1% 1fr 1fr;
                    grid-template-rows: 400px 400px;
                    grid-auto-rows: 400px;
                }
            }
        }
    }

    .service-item.big {
        .service-item-holder { 
            .image {
                height: 609px; 
            }
        }
    }
    .service-item { 
        .service-item-holder { 
            .image {

            }

            .desc { 
                .desc-holder { 
                    padding: 25px 25px 0;
                    .name {
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .services-block { 
        padding: 49px 0 59px;
        overflow: hidden;
        margin: 0 0 -3px;
        .services-block-holder { 
            .services-grid { 

                .slick-slider {
                    width: calc(100% + 10px);
                    margin-left: -5px;
                    padding: 0 30.8% 0 0;
                    box-sizing: border-box;
                    .slick-list {
                        overflow: visible;
                        .slick-track {
                            .slick-slide {
                                padding: 0 5px;
                            }
                        }
                    }
                }

                .services-grid-holder { 
                    display: inline-block;
                }
            }
        }
    }

    .service-item.big {
        .service-item-holder { 
            .image {
                height: 200px; 
            }

            .desc { 
                .desc-holder { 
                    .name { 
                        font-size: 16px;
                        line-height: 120%;
                    }
                }
            }
        }
    }

    .service-item { 
        .service-item-holder { 
            .image { 

            }

            .desc {
                .desc-holder {
                    padding: 25px 25px 20px;
                    .name {
                        font-size: 16px;
                        line-height: 120%;
                        margin: 0 0 5px;
                    }

                    .price { 
                        font-size: 14px;
                        margin: 0 0 15px;
                    }

                    >.text { 
                        font-size: 14px;
                        margin: 0 0 10px;
                    }

                    .action { 
                        a {
                            font-size: 14px;
                            .icon { 
                                padding: 3px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
