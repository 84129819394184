.breadcrumbs {
  display: inline-block;
  width: 100%;
  margin-top: 27px;
  margin-bottom: 27px;
}
.breadcrumbs .breadcrumbs-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
  display: inline;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  margin-right: 0;
  color: #000;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a {
  color: #D7D7D7;
  text-decoration: none;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a:hover {
  color: #CF2127;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep {
  position: relative;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 15px;
  border-left: 1px solid #D7D7D7;
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-2px);
}

@media (max-width: 1199px) {
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
    font-size: 10px;
    margin-right: 1px;
  }
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep:after {
    height: 10px;
  }
}
.pagination {
  display: inline-block;
  width: 100%;
  margin: 25px 0 0;
}
.pagination ul {
  float: right;
  width: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-right: -19px;
}
.pagination ul > li {
  float: left;
  margin: 0 5px;
}
.pagination ul > li.next a, .pagination ul > li.prev a {
  background: none;
}
.pagination ul > li.next a:hover, .pagination ul > li.prev a:hover {
  background: none;
  color: #CF2127;
}
.pagination ul > li.next a svg, .pagination ul > li.prev a svg {
  width: 17px;
  height: 12px;
  vertical-align: middle;
}
.pagination ul > li.next a svg {
  transform: rotate(180deg);
}
.pagination ul > li.middle a {
  background: none;
  width: auto;
  vertical-align: bottom;
  line-height: 60px;
  overflow: hidden;
}
.pagination ul > li.middle a:hover {
  background: none;
  color: #CF2127;
}
.pagination ul > li.current a {
  background-color: #CF2127;
  color: white;
  pointer-events: none;
  touch-action: none;
}
.pagination ul > li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: none;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  color: #000;
  text-decoration: none;
  border-radius: 0;
  transition: all 300ms;
  background-color: #F2F5F7;
}
.pagination ul > li a:hover {
  background-color: #CF2127;
  color: white;
  transition: all 300ms;
}

@media (max-width: 1199px) {
  .pagination {
    margin: 4px 0 0;
  }
  .pagination ul {
    margin-right: -9px;
  }
  .pagination ul > li.middle a {
    line-height: 50px;
  }
  .pagination ul > li a {
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .pagination {
    margin: 33px 0 0;
  }
  .pagination ul {
    margin-right: -2.5px;
    width: calc(100% + 5px);
  }
  .pagination ul > li {
    margin: 0 2.5px;
  }
}
.services-list {
  display: inline-block;
  width: 100%;
  margin: 0 0 73px;
}
.services-list .services-list-holder {
  display: inline-block;
  width: 100%;
  position: relative;
}
.services-list .services-list-holder .services-background {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
}
.services-list .services-list-holder .services-background img {
  width: 784px;
  height: 479px;
  object-fit: contain;
}
.services-list .services-list-holder .services-categories {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
  position: relative;
  box-sizing: border-box;
  padding-right: 51%;
  z-index: 2;
}
.services-list .services-list-holder .services-categories .category-col {
  width: 50%;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0 0 32px;
}
.services-list .services-list-holder .services-categories .category-col .category-col-holder {
  display: inline-block;
  width: 100%;
}
.services-list .services-list-holder .services-categories .category-col .category-col-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 13px;
}
.services-list .services-list-holder .services-categories .category-col .category-col-holder .heading a {
  color: #000;
}
.services-list .services-list-holder .services-categories .category-col .category-col-holder .heading a:hover {
  color: #CF2127;
}
.services-list .services-list-holder .services-categories .category-col .category-col-holder .services-links {
  display: inline-block;
  width: 100%;
}
.services-list .services-list-holder .services-categories .category-col .category-col-holder .services-links ul {
  display: inline-block;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}
.services-list .services-list-holder .services-categories .category-col .category-col-holder .services-links ul li {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 3px;
}
.services-list .services-list-holder .services-categories .category-col .category-col-holder .services-links ul li:last-of-type {
  margin: 0;
}
.services-list .services-list-holder .services-categories .category-col .category-col-holder .services-links ul li a {
  color: #000;
}
.services-list .services-list-holder .services-categories .category-col .category-col-holder .services-links ul li a:hover {
  color: #CF2127;
}

@media (max-width: 1469px) {
  .services-list {
    margin: 0 0 33px;
  }
  .services-list .services-list-holder .services-background {
    transform: translateY(-55%);
  }
  .services-list .services-list-holder .services-background img {
    width: 591px;
    height: 365px;
  }
}
@media (max-width: 1199px) {
  .services-list {
    margin: 0 0 33px;
  }
  .services-list .services-list-holder .services-background {
    display: none;
  }
  .services-list .services-list-holder .services-categories {
    padding: 0;
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .services-list .services-list-holder .services-categories .category-col {
    width: 33.333%;
    padding: 0 5px;
    margin: 0 0 27px;
  }
  .services-list .services-list-holder .services-categories .category-col .category-col-holder .services-links ul li {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .services-list {
    margin: 0 0 22px;
  }
  .services-list .services-list-holder .services-categories {
    width: 100%;
    margin-left: 0;
  }
  .services-list .services-list-holder .services-categories .category-col {
    width: 100%;
    padding: 0;
    margin: 0 0 29px;
  }
  .services-list .services-list-holder .services-categories .category-col .category-col-holder .heading {
    margin: 0 0 9px;
  }
  .services-list .services-list-holder .services-categories .category-col .category-col-holder .services-links ul li {
    font-size: 10px;
    margin: 0 0 4px;
  }
}
.service-details {
  display: inline-block;
  width: 100%;
  margin: 0 0 65px;
}
.service-details .service-details-holder {
  display: inline-block;
  width: 100%;
}
.service-details .service-details-holder .image-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 40px;
}
.service-details .service-details-holder .image-block .image-block-holder {
  display: inline-block;
  width: 100%;
}
.service-details .service-details-holder .image-block .image-block-holder .image {
  display: inline-block;
  width: 100%;
  height: 540px;
  position: relative;
  z-index: 1;
}
.service-details .service-details-holder .image-block .image-block-holder .image img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.service-details .service-details-holder .image-block .image-block-holder .order-block {
  width: 49%;
  margin: -61px auto 0;
  position: relative;
  z-index: 2;
}
.service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder {
  width: 100%;
  background-color: #CF2127;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 30px 40px;
}
.service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .pricing .price {
  margin: 0 0 4px;
}
.service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .pricing .timing {
  color: #E79093;
  font-size: 14px;
  line-height: 140%;
}
.service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .action {
  width: 280px;
}
.service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .action .btn {
  width: 100%;
  text-align: center;
  background-color: transparent;
  color: white;
  border-color: white;
}
.service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .action .btn:hover {
  color: #CF2127;
  background-color: white;
}
.service-details .service-details-holder .desc {
  display: inline-block;
  width: 100%;
}
.service-details .service-details-holder .desc .desc-holder {
  display: inline-block;
  width: 100%;
}
.service-details .service-details-holder .desc .desc-holder h3, .service-details .service-details-holder .desc .desc-holder .h3 {
  margin: 0 0 30px;
}
.service-details .service-details-holder .desc .desc-holder p {
  color: #979797;
  margin: 0 0 15px;
}

@media (max-width: 1469px) {
  .service-details {
    margin: 0 0 47px;
  }
  .service-details .service-details-holder .image-block .image-block-holder .order-block {
    width: 65%;
  }
}
@media (max-width: 1199px) {
  .service-details {
    margin: 0 0 20px;
  }
  .service-details .service-details-holder .image-block {
    margin: 0 0 30px;
  }
  .service-details .service-details-holder .image-block .image-block-holder .image {
    height: 400px;
  }
  .service-details .service-details-holder .image-block .image-block-holder .order-block {
    width: 83%;
    margin: -55px auto 0;
  }
  .service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .pricing .timing {
    font-size: 12px;
  }
  .service-details .service-details-holder .desc .desc-holder {
    margin: 0 0 18px;
  }
  .service-details .service-details-holder .desc .desc-holder h3, .service-details .service-details-holder .desc .desc-holder .h3 {
    margin: 0 0 21px;
  }
}
@media (max-width: 767px) {
  .service-details {
    margin: 0 0 9px;
  }
  .service-details .service-details-holder .image-block .image-block-holder .order-block {
    margin: 23px 0 0;
    width: 100%;
  }
  .service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder {
    background-color: transparent;
    color: black;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }
  .service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .pricing {
    display: flex;
    align-items: center;
    margin: 0 0 22px;
  }
  .service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .pricing .price {
    margin: 0;
  }
  .service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .pricing .timing {
    color: #979797;
    font-size: 10px;
    margin: 0 0 0 12px;
  }
  .service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .action {
    width: 100%;
  }
  .service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .action .btn {
    width: 100%;
    text-align: center;
    background-color: #CF2127;
    color: white;
    border-color: #CF2127;
  }
  .service-details .service-details-holder .image-block .image-block-holder .order-block .order-block-holder .action .btn:hover {
    color: white;
    background-color: #B60F15;
    border-color: #B60F15;
  }
  .service-details .service-details-holder .desc .desc-holder h3, .service-details .service-details-holder .desc .desc-holder .h3 {
    margin: 0 0 23px;
  }
}
.additional-services-block {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  background-color: #F2F5F7;
  box-sizing: border-box;
  padding: 66px 0 64px;
}
.additional-services-block .additional-services-block-holder {
  display: inline-block;
  width: 100%;
}
.additional-services-block .additional-services-block-holder .services-slider .service-item .service-item-holder .desc .desc-holder .name {
  -webkit-line-clamp: 1;
  line-clamp: 1 a;
}
@media (max-width: 1469px) {
  .additional-services-block {
    overflow: hidden;
  }
  .additional-services-block .additional-services-block-holder .heading {
    margin: 0 0 37px;
  }
  .additional-services-block .additional-services-block-holder .slick-slider {
    width: calc(100% + 20px);
    margin-left: -10px;
    box-sizing: border-box;
    padding-right: 3.4%;
  }
  .additional-services-block .additional-services-block-holder .slick-slider .slick-list {
    overflow: visible;
  }
  .additional-services-block .additional-services-block-holder .slick-slider .slick-list .slick-slide {
    padding: 0 10px;
  }
  .additional-services-block .additional-services-block-holder .services-slider .service-item .service-item-holder .desc .desc-holder {
    padding: 30px 30px 26px;
  }
}
@media (max-width: 1199px) {
  .additional-services-block {
    padding: 52px 0 49px;
  }
  .additional-services-block .additional-services-block-holder .slick-slider {
    width: calc(100% + 10px);
    margin-left: -5px;
    box-sizing: border-box;
    padding-right: 31%;
  }
  .additional-services-block .additional-services-block-holder .slick-slider .slick-list {
    overflow: visible;
  }
  .additional-services-block .additional-services-block-holder .slick-slider .slick-list .slick-slide {
    padding: 0 5px;
  }
  .additional-services-block .additional-services-block-holder .services-slider .service-item .service-item-holder .desc .desc-holder {
    padding: 25px 25px 20px;
  }
}
@media (max-width: 767px) {
  .additional-services-block {
    padding: 39px 0 58px;
  }
  .additional-services-block .additional-services-block-holder .slick-slider {
    width: calc(100% + 15px);
    margin-left: -7.5px;
    box-sizing: border-box;
    padding-right: 6.7%;
  }
  .additional-services-block .additional-services-block-holder .slick-slider .slick-list {
    overflow: visible;
  }
  .additional-services-block .additional-services-block-holder .slick-slider .slick-list .slick-slide {
    padding: 0 7.5px;
  }
  .additional-services-block .additional-services-block-holder .services-slider .service-item .service-item-holder .desc .desc-holder {
    padding: 20px 20px 15px;
  }
}
.work-steps-block {
  display: inline-block;
  width: 100%;
  background-color: #F2F5F7;
  padding: 63px 0 64px;
}
.work-steps-block .work-steps-block-holder {
  display: inline-block;
  width: 100%;
}
.work-steps-block .work-steps-block-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 40px;
}
.work-steps-block .work-steps-block-holder .steps {
  display: flex;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.work-steps-block .work-steps-block-holder .steps .step {
  width: 25%;
  height: auto;
  box-sizing: border-box;
  padding: 0 15px;
}
.work-steps-block .work-steps-block-holder .steps .step .step-holder {
  width: 100%;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 25px 25px;
}
.work-steps-block .work-steps-block-holder .steps .step .step-holder .number {
  display: inline-block;
  width: 100%;
  margin: 0 0 26px;
}
.work-steps-block .work-steps-block-holder .steps .step .step-holder .number .number-holder {
  float: left;
  width: 44px;
  height: 44px;
  line-height: 44px;
  background-color: #CF2127;
  color: white;
  font-size: 28px;
  text-align: center;
}
.work-steps-block .work-steps-block-holder .steps .step .step-holder .text {
  display: inline-block;
  width: 100%;
}
.work-steps-block .work-steps-block-holder .steps .step .step-holder .text .text-holder {
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: 1469px) {
  .work-steps-block {
    padding: 68px 0 46px;
  }
  .work-steps-block .work-steps-block-holder .steps {
    flex-wrap: wrap;
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .work-steps-block .work-steps-block-holder .steps .step {
    width: 50%;
    padding: 0 10px;
    margin: 0 0 20px;
  }
  .work-steps-block .work-steps-block-holder .steps .step .step-holder {
    display: flex;
    align-items: center;
  }
  .work-steps-block .work-steps-block-holder .steps .step .step-holder .number {
    width: 44px;
    margin: 0;
  }
  .work-steps-block .work-steps-block-holder .steps .step .step-holder .text {
    width: calc(100% - 44px);
    box-sizing: border-box;
    padding-left: 20px;
  }
}
@media (max-width: 1199px) {
  .work-steps-block {
    padding: 48px 0 39px;
  }
  .work-steps-block .work-steps-block-holder .heading {
    margin: 0 0 30px;
  }
  .work-steps-block .work-steps-block-holder .steps {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .work-steps-block .work-steps-block-holder .steps .step {
    padding: 0 5px;
    margin: 0 0 10px;
  }
  .work-steps-block .work-steps-block-holder .steps .step .step-holder .number {
    width: 39px;
  }
  .work-steps-block .work-steps-block-holder .steps .step .step-holder .number .number-holder {
    width: 39px;
    height: 39px;
    line-height: 39px;
    font-size: 24px;
  }
  .work-steps-block .work-steps-block-holder .steps .step .step-holder .text {
    width: calc(100% - 39px);
  }
  .work-steps-block .work-steps-block-holder .steps .step .step-holder .text .text-holder {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .work-steps-block {
    padding: 38px 0 32px;
  }
  .work-steps-block .work-steps-block-holder .steps {
    width: 100%;
    margin: 0;
  }
  .work-steps-block .work-steps-block-holder .steps .step {
    width: 100%;
    padding: 0;
  }
  .work-steps-block .work-steps-block-holder .steps .step .step-holder {
    padding: 20px 20px;
  }
  .work-steps-block .work-steps-block-holder .steps .step .step-holder .text {
    padding-left: 15px;
  }
  .work-steps-block .work-steps-block-holder .steps .step .step-holder .text .text-holder {
    font-size: 12px;
  }
}
.partner-promo {
  display: inline-block;
  width: 100%;
  padding: 65px 0 41px;
}
.partner-promo .partner-promo-holder {
  display: inline-block;
  width: 100%;
}
.partner-promo .partner-promo-holder .partner-desc {
  display: flex;
  width: 100%;
}
.partner-promo .partner-promo-holder .partner-desc .image {
  width: 470px;
  height: auto;
}
.partner-promo .partner-promo-holder .partner-desc .image .image-holder {
  width: 100%;
  height: 100%;
}
.partner-promo .partner-promo-holder .partner-desc .image .image-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.partner-promo .partner-promo-holder .partner-desc .text {
  width: calc(100% - 470px);
  box-sizing: border-box;
  padding-left: 30px;
}
.partner-promo .partner-promo-holder .partner-desc .text .text-holder {
  width: 100%;
}
.partner-promo .partner-promo-holder .partner-desc .text .text-holder .inner-text {
  width: 100%;
}
.partner-promo .partner-promo-holder .partner-desc .text .text-holder .inner-text h2, .partner-promo .partner-promo-holder .partner-desc .text .text-holder .inner-text .h2 {
  margin: 0 0 19px;
}
.partner-promo .partner-promo-holder .partner-desc .text .text-holder .inner-text p {
  color: #979797;
}
.partner-promo .partner-promo-holder .partner-desc .text .text-holder .action {
  width: 100%;
  margin: 31px 0 0;
}
.partner-promo .partner-promo-holder .partner-desc .text .text-holder .action .btn {
  padding: 13px 34px;
}

@media (max-width: 1469px) {
  .partner-promo .partner-promo-holder .partner-desc .image {
    width: 450px;
  }
  .partner-promo .partner-promo-holder .partner-desc .text {
    width: calc(100% - 450px);
    padding-left: 21px;
  }
}
@media (max-width: 1199px) {
  .partner-promo {
    padding: 51px 0 41px;
  }
  .partner-promo .partner-promo-holder .partner-desc .text {
    width: 100%;
    padding-left: 0;
  }
  .partner-promo .partner-promo-holder .partner-desc .text .text-holder .action .btn {
    padding: 12px 46px;
  }
}
@media (max-width: 767px) {
  .partner-promo {
    padding: 39px 0 41px;
  }
  .partner-promo .partner-promo-holder .partner-desc .text .text-holder .action .btn {
    width: 100%;
    text-align: center;
  }
}
.work-examples-block {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  margin: 0 0 62px;
}
.work-examples-block .work-examples-block-holder {
  display: inline-block;
  width: 100%;
}
.work-examples-block .work-examples-block-holder .slick-slider {
  padding-right: 0%;
  box-sizing: border-box;
}
.work-examples-block .work-examples-block-holder .slick-slider .slick-list {
  overflow: visible;
}
.work-examples-block .work-examples-block-holder .exmaples-slider .example-image {
  display: inline-block;
  width: 100%;
  height: 470px;
}
.work-examples-block .work-examples-block-holder .exmaples-slider .example-image .example-image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.work-examples-block .work-examples-block-holder .exmaples-slider .example-image .example-image-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.work-examples-block .work-examples-block-holder .exmaples-slider .example-image .example-image-holder a:hover img {
  transform: scale(1.1);
  transition: all 300ms;
}
.work-examples-block .work-examples-block-holder .exmaples-slider .example-image .example-image-holder a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}

@media (max-width: 1469px) {
  .work-examples-block .work-examples-block-holder .slick-slider {
    padding-right: 13.8%;
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .work-examples-block .work-examples-block-holder .slick-slider .slick-list .slick-slide {
    padding: 0 10px;
  }
}
@media (max-width: 1199px) {
  .work-examples-block {
    margin: 0 0 46px;
  }
  .work-examples-block .work-examples-block-holder .slick-slider {
    padding-right: 30.8%;
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .work-examples-block .work-examples-block-holder .slick-slider .slick-list .slick-slide {
    padding: 0 5px;
  }
  .work-examples-block .work-examples-block-holder .exmaples-slider .example-image {
    height: 270px;
  }
}
@media (max-width: 767px) {
  .work-examples-block {
    margin: 0 0 57px;
  }
  .work-examples-block .work-examples-block-holder .slick-slider {
    padding-right: 6.8%;
  }
}
.glass-type-select {
  display: inline-block;
  width: 100%;
  margin: 0 0 26px;
}
.glass-type-select .glass-type-select-holder {
  display: inline-block;
  width: 100%;
}
.glass-type-select .glass-type-select-holder .glass-types {
  width: calc(94.5% + 15px);
  margin-left: -7.5px;
}
.glass-type-select .glass-type-select-holder .glass-types .type {
  float: left;
  width: 20%;
  box-sizing: border-box;
  padding: 0 7.5px;
}
.glass-type-select .glass-type-select-holder .glass-types .type .type-holder {
  display: inline-block;
  width: 100%;
}
.glass-type-select .glass-type-select-holder .glass-types .type .type-holder a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  background-color: #F2F5F7;
  color: #000;
  padding: 20px 20px;
  box-sizing: border-box;
}
.glass-type-select .glass-type-select-holder .glass-types .type .type-holder a:hover {
  color: #CF2127;
}
.glass-type-select .glass-type-select-holder .glass-types .type .type-holder a .icon {
  float: left;
  width: 30px;
  height: 30px;
  color: #CF2127;
}
.glass-type-select .glass-type-select-holder .glass-types .type .type-holder a .text {
  width: calc(100% - 30px);
  box-sizing: border-box;
  padding-left: 15px;
}

@media (max-width: 1469px) {
  .glass-type-select {
    margin: 0 0 38px;
  }
  .glass-type-select .glass-type-select-holder .glass-types {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .glass-type-select .glass-type-select-holder .glass-types .type {
    width: 25%;
    padding: 0 10px;
  }
}
@media (max-width: 1199px) {
  .glass-type-select {
    margin: 0 0 26px;
    overflow-y: auto;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
  }
  .glass-type-select .glass-type-select-holder::-webkit-scrollbar {
    display: none !important;
  }
  .glass-type-select .glass-type-select-holder .glass-types {
    display: flex;
    flex-wrap: nowrap;
    width: calc(200% + 10px);
    margin-left: -5px;
  }
  .glass-type-select .glass-type-select-holder .glass-types .type {
    width: auto;
    padding: 0 5px;
  }
  .glass-type-select .glass-type-select-holder .glass-types .type .type-holder a {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .glass-type-select {
    margin: 0 0 13px;
  }
  .glass-type-select .glass-type-select-holder .glass-types {
    width: calc(300% + 10px);
  }
  .glass-type-select .glass-type-select-holder .glass-types .type .type-holder a {
    font-size: 10px;
    padding: 10px 15px;
  }
  .glass-type-select .glass-type-select-holder .glass-types .type .type-holder a .icon {
    width: 20px;
    height: 20px;
  }
  .glass-type-select .glass-type-select-holder .glass-types .type .type-holder a .icon svg {
    width: 100%;
    height: 100%;
  }
  .glass-type-select .glass-type-select-holder .glass-types .type .type-holder a .text {
    width: calc(100% - 20px);
    padding-left: 15px;
  }
}
.catalog-block {
  display: inline-block;
  width: 100%;
}
.catalog-block .catalog-block-holder {
  display: inline-block;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.catalog-block .catalog-block-holder .col {
  float: left;
  width: 375px;
  box-sizing: border-box;
  padding: 0 15px;
}
.catalog-block .catalog-block-holder .col .col-holder {
  display: inline-block;
  width: 100%;
}
.catalog-block .catalog-block-holder .main-content {
  float: left;
  width: calc(100% - 375px);
  box-sizing: border-box;
  padding: 0 15px;
}
.catalog-block .catalog-block-holder .main-content .main-content-holder {
  display: inline-block;
  width: 100%;
}

@media (max-width: 1469px) {
  .catalog-block .catalog-block-holder {
    width: 100%;
    margin: 0;
  }
  .catalog-block .catalog-block-holder .col {
    width: 100%;
    padding: 0;
    margin: 0 0 35px;
  }
  .catalog-block .catalog-block-holder .main-content {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 1199px) {
  .catalog-block .catalog-block-holder .col {
    margin: 0 0 26px;
  }
}
@media (max-width: 767px) {
  .catalog-block .catalog-block-holder .col {
    margin: 0 0 17px;
  }
}
.active-filters {
  display: inline-block;
  width: 100%;
  margin: 0 0 22px;
}
.active-filters .active-filters-holder {
  display: inline-block;
  width: 100%;
}
.active-filters .active-filters-holder .filter-item {
  float: left;
  margin-right: 4px;
  margin-bottom: 4px;
}
.active-filters .active-filters-holder .filter-item .filter-item-holder {
  display: flex;
  align-items: center;
  background-color: #F2F5F7;
  padding: 7px 7px 7px;
}
.active-filters .active-filters-holder .filter-item .filter-item-holder .name {
  float: left;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
}
.active-filters .active-filters-holder .filter-item .filter-item-holder .action {
  float: left;
  margin-left: 7px;
  width: 8px;
  height: 7px;
}
.active-filters .active-filters-holder .filter-item .filter-item-holder .action a {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #000;
}
.active-filters .active-filters-holder .filter-item .filter-item-holder .action a:hover {
  color: #CF2127;
}
.active-filters .active-filters-holder .filter-item .filter-item-holder .action a svg {
  width: 100%;
  height: auto;
}

@media (max-width: 1199px) {
  .active-filters {
    margin: 0 0 12px;
  }
  .active-filters .active-filters-holder .filter-item .filter-item-holder .name {
    font-size: 10px;
  }
}
@media (max-width: 767px) {
  .active-filters .active-filters-holder {
    width: calc(100% + 10px);
  }
  .active-filters .active-filters-holder .filter-item {
    margin-right: 4px;
    margin-bottom: 4px;
  }
  .active-filters .active-filters-holder .filter-item .filter-item-holder {
    padding: 6px 7px 6px;
  }
}
.catalog-filter {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder .heading {
  margin: 0 0 19px;
}
.catalog-filter .catalog-filter-holder form .form-field {
  margin-bottom: 18.5px;
}
.catalog-filter .catalog-filter-holder form .action {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder form .action .action-holder {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder form .action .action-holder .submit {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder form .action .action-holder .submit .btn {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.catalog-filter-mobile {
  display: inline-block;
  width: 100%;
}
.catalog-filter-mobile .catalog-filter-mobile-holder {
  display: inline-block;
  width: 100%;
}
.catalog-filter-mobile .catalog-filter-mobile-holder .btn {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 13px 14px;
}
.catalog-filter-mobile .catalog-filter-mobile-holder .btn .text {
  float: left;
}
.catalog-filter-mobile .catalog-filter-mobile-holder .btn .icon {
  float: left;
  width: 16px;
  height: 17px;
  margin-left: 5px;
}
.catalog-filter-mobile .catalog-filter-mobile-holder .btn .icon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 1469px) {
  .catalog-filter .catalog-filter-holder form .form-group .form-col.col-1 {
    width: 50%;
  }
  .catalog-filter .catalog-filter-holder form .form-group .form-col.col-2 {
    width: 33.333%;
  }
  .catalog-filter .catalog-filter-holder form .action .action-holder .submit {
    width: 280px;
    float: right;
  }
}
@media (max-width: 1199px) {
  .catalog-filter .catalog-filter-holder form .form-group {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .catalog-filter .catalog-filter-holder form .form-group .form-col {
    padding: 0 5px;
  }
  .catalog-filter .catalog-filter-holder form .form-field {
    margin-bottom: 13.5px;
  }
  .catalog-filter .catalog-filter-holder form .action .action-holder .submit {
    width: 191px;
    float: right;
  }
}
@media (max-width: 767px) {
  .catalog-filter .catalog-filter-holder form .form-group {
    width: 100%;
    margin: 0;
  }
  .catalog-filter .catalog-filter-holder form .form-group .form-col {
    padding: 0;
  }
  .catalog-filter .catalog-filter-holder form .form-group .form-col.col-1 {
    width: 100%;
  }
  .catalog-filter .catalog-filter-holder form .form-group .form-col.col-2 {
    width: 100%;
  }
  .catalog-filter .catalog-filter-holder form .form-field {
    margin-bottom: 9.5px;
  }
  .catalog-filter .catalog-filter-holder form .action .action-holder .submit {
    width: 100%;
    float: left;
    margin: 0 0 15px;
  }
}
.items-list {
  display: inline-block;
  width: 100%;
  margin: 0 0 12px;
}
.items-list .items-list-holder {
  display: inline-block;
  width: 100%;
}
.items-list .items-list-holder .catalog-item {
  float: left;
  width: 100%;
  margin: 0 0 -3px;
}

.catalog-item {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder {
  display: inline-block;
  width: 100%;
  border: 1px solid #F2F5F7;
  box-sizing: border-box;
  padding: 30px;
}
.catalog-item .catalog-item-holder .image {
  float: left;
  width: 170px;
  height: 130px;
}
.catalog-item .catalog-item-holder .image .image-holder {
  width: 100%;
  height: 100%;
}
.catalog-item .catalog-item-holder .image .image-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.catalog-item .catalog-item-holder .image .image-holder a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.catalog-item .catalog-item-holder .about-item {
  float: left;
  width: calc(100% - 170px);
  box-sizing: border-box;
  padding-left: 30px;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc {
  float: left;
  width: calc(100% - 280px);
  box-sizing: border-box;
  padding-right: 30px;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder > .name {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  margin: 0 0 3px;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder > .name a {
  color: #000;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder > .name a:hover {
  color: #CF2127;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .waranty {
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .waranty .value {
  float: left;
  margin-right: 4px;
  background-color: #F2F5F7;
  padding: 7px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  text-align: left;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .parameters {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .parameters .parameters-holder {
  display: flex;
  width: calc(100% + 15px);
  margin-left: -7.5px;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .parameters .parameters-holder .param {
  width: 25%;
  box-sizing: border-box;
  padding: 0 7.5PX;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .parameters .parameters-holder .param .param-holder {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .parameters .parameters-holder .param .param-holder .name {
  display: inline-block;
  width: 100%;
  color: #979797;
  margin: 0 0 5px;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .parameters .parameters-holder .param .param-holder .value {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-order {
  float: right;
  width: 280px;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder .price {
  display: inline-block;
  width: 100%;
  text-align: right;
  margin: 0 0 20px;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder .price .value {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder .price .add-price {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: #979797;
  margin: 5px 0 0;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder .action {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder .action .btn {
  display: inline-block;
  width: 100%;
  text-align: center;
}

@media (max-width: 1199px) {
  .items-list {
    margin: 0 0 29px;
  }
  .items-list .items-list-holder .catalog-item {
    margin: 0 0 25px;
  }
  .items-list .items-list-holder .catalog-item:last-of-type {
    margin: 0;
  }
  .catalog-item .catalog-item-holder {
    padding: 0 0 30px;
    border: none;
    border-bottom: 1px solid #F2F5F7;
  }
  .catalog-item .catalog-item-holder .image {
    width: 124px;
    height: 90px;
  }
  .catalog-item .catalog-item-holder .about-item {
    padding-left: 20px;
    width: calc(100% - 124px);
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc {
    width: calc(100% - 190px);
    padding-right: 30px;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder > .name {
    font-size: 16px;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .waranty .value {
    font-size: 10px;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .parameters .parameters-holder .param .param-holder {
    font-size: 12px;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-order {
    width: 190px;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder .price {
    margin: 0 0 9px;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder .price .add-price {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .items-list {
    margin: 0 0 20px;
  }
  .items-list .items-list-holder .catalog-item {
    margin: 0 0 26px;
  }
  .catalog-item .catalog-item-holder {
    border: 1px solid #F2F5F7;
    box-sizing: border-box;
    padding: 20px 20px 0;
  }
  .catalog-item .catalog-item-holder .image {
    width: 100%;
    height: 50vw;
    margin: 0 0 18px;
  }
  .catalog-item .catalog-item-holder .about-item {
    width: 100%;
    padding: 0;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc {
    width: 100%;
    padding: 0;
    margin: 0 0 17px;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .parameters .parameters-holder {
    flex-wrap: wrap;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .parameters .parameters-holder .param {
    width: 50%;
    margin: 0 0 14px;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-desc .item-desc-holder .parameters .parameters-holder .param .param-holder {
    font-size: 10px;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-order {
    width: 100%;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder .price {
    text-align: left;
    margin: 0 0 20px;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder .price .add-price {
    font-size: 10px;
    margin: 3px 0 0;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder .action {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .catalog-item .catalog-item-holder .about-item .about-item-holder .item-order .item-order-holder .action .btn {
    background-color: #CF2127;
    color: white;
  }
}
.glass-form {
  display: inline-block;
  width: 100%;
  padding: 50px 0 50px;
}
.glass-form.padding-to-margin {
  padding: 0;
  margin: 0 0 34px;
}
.glass-form .glass-form-holder {
  display: inline-block;
  width: 100%;
}
.glass-form .glass-form-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 41px;
}
.glass-form .glass-form-holder .selection-form {
  display: inline-block;
  width: 100%;
  background-color: #F2F5F7;
  box-sizing: border-box;
  padding: 40px;
}
.glass-form .glass-form-holder .selection-form form {
  display: inline-block;
  width: calc(100% + 60px);
  margin-left: -30px;
}
.glass-form .glass-form-holder .selection-form form .fields {
  width: calc(100% - 395px);
  float: left;
  box-sizing: border-box;
  padding: 0 30px;
}
.glass-form .glass-form-holder .selection-form form .fields .fields-holder {
  display: inline-block;
  width: 100%;
}
.glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group {
  width: calc(100% + 10px);
  margin-left: -5px;
}
.glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col {
  float: left;
  box-sizing: border-box;
  padding: 0 5px;
}
.glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col .form-field {
  display: inline-block;
  width: 100%;
  margin: 0;
}
.glass-form .glass-form-holder .selection-form form .action-col {
  width: 395px;
  float: left;
  box-sizing: border-box;
  padding: 0 30px;
}
.glass-form .glass-form-holder .selection-form form .action-col .action-col-holder {
  display: inline-block;
  width: 100%;
}
.glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
}
.glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action .submit {
  display: inline-block;
  width: 100%;
}
.glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action .submit .btn {
  display: inline-block;
  width: 100%;
}

@media (max-width: 1469px) {
  .glass-form {
    padding: 30px 0 30px;
  }
  .glass-form.padding-to-margin {
    margin: 0 0 41px;
  }
  .glass-form .glass-form-holder .selection-form {
    padding: 29px 30px 24px;
  }
  .glass-form .glass-form-holder .selection-form form {
    width: calc(100% + 15px);
    margin-left: -7.5px;
  }
  .glass-form .glass-form-holder .selection-form form .fields {
    width: calc(100% - 300px);
    padding: 0 7.5px;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group {
    width: calc(100% + 15px);
    margin-left: -7.5px;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col {
    padding: 0 7.5px;
  }
  .glass-form .glass-form-holder .selection-form form .action-col {
    width: 300px;
    padding: 0 7.5px;
  }
}
@media (max-width: 1199px) {
  .glass-form.padding-to-margin {
    margin: 0 0 24px;
  }
  .glass-form .glass-form-holder .heading {
    margin: 0 0 29px;
  }
  .glass-form .glass-form-holder .selection-form {
    background-color: transparent;
    padding: 0;
  }
  .glass-form .glass-form-holder .selection-form form {
    width: calc(100% + 22px);
    margin-left: -11px;
  }
  .glass-form .glass-form-holder .selection-form form .fields {
    width: calc(100% - 67px);
    padding: 0 11px;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col {
    padding: 0 5px;
  }
  .glass-form .glass-form-holder .selection-form form .action-col {
    width: 67px;
    padding: 0 11px;
  }
  .glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action .submit .btn {
    float: right;
    width: 45px;
    height: 45px;
  }
}
@media (max-width: 767px) {
  .glass-form {
    padding: 20px 0 20px;
  }
  .glass-form .glass-form-holder .selection-form form {
    width: 100%;
    margin: 0;
  }
  .glass-form .glass-form-holder .selection-form form .fields {
    width: 100%;
    padding: 0;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group {
    width: 100%;
    margin: 0;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col {
    width: 100%;
    padding: 0;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col .form-field {
    margin: 0 0 8px;
  }
  .glass-form .glass-form-holder .selection-form form .action-col {
    width: 100%;
    padding: 0;
  }
  .glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action {
    margin: 21px 0 0;
  }
  .glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action .submit .btn {
    width: 100%;
  }
}
.models-list-block {
  display: inline-block;
  width: 100%;
  padding: 23px 0 80px;
}
.models-list-block.padding-to-margin {
  padding: 0;
  margin: 0 0 45px;
}
.models-list-block .models-list-block-holder {
  display: inline-block;
  width: 100%;
}
.models-list-block .models-list-block-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 40px;
}
.models-list-block .models-list-block-holder .models-list {
  display: inline-block;
  width: 100%;
}
.models-list-block .models-list-block-holder .models-list.tighter .models-list-holder .model-item {
  margin: 0 0 14.5px;
}
.models-list-block .models-list-block-holder .models-list.tighter .models-list-holder .model-item .model-item-holder .name a .name {
  width: 100%;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder {
  display: inline-block;
  width: 100%;
  column-count: 6;
  column-gap: 31px;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item {
  display: inline-block;
  width: 100%;
  margin: 0 0 20px;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder {
  display: inline-block;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding-left: 35px;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .letter {
  position: absolute;
  left: 0;
  top: -2px;
  font-size: 28px;
  font-weight: 500;
  line-height: 140%;
  color: #CF2127;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name {
  display: inline-block;
  width: 100%;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: #000;
  display: flex;
  align-items: center;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a:hover {
  color: #CF2127;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .icon {
  float: left;
  margin-right: 15px;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .icon img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .name {
  width: calc(100% - 40px - 15px);
  float: left;
}
.models-list-block .models-list-block-holder .action {
  margin: 20px 0 0;
}
.models-list-block .models-list-block-holder .action .btn {
  padding: 14px 31px;
}

@media (max-width: 1469px) {
  .models-list-block {
    padding: 30px 0 58px;
  }
  .models-list-block .models-list-block-holder .models-list.lg-4-col .models-list-holder {
    column-count: 4;
  }
  .models-list-block .models-list-block-holder .models-list.lg-6-col .models-list-holder {
    column-count: 6;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder {
    column-count: 3;
    column-gap: 21px;
  }
}
@media (max-width: 1199px) {
  .models-list-block {
    padding: 25px 0 50px;
  }
  .models-list-block .models-list-block-holder .heading {
    margin: 0 0 31px;
  }
  .models-list-block .models-list-block-holder .models-list.md-3-col .models-list-holder {
    column-count: 3;
  }
  .models-list-block .models-list-block-holder .models-list.md-6-col .models-list-holder {
    column-count: 6;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a {
    font-size: 14px;
  }
  .models-list-block .models-list-block-holder .action {
    margin: 10px 0 0;
  }
  .models-list-block .models-list-block-holder .action .btn {
    padding: 14px 42px;
  }
}
@media (max-width: 767px) {
  .models-list-block {
    padding: 25px 0 40px;
  }
  .models-list-block .models-list-block-holder .heading {
    margin: 0 0 29px;
  }
  .models-list-block .models-list-block-holder .models-list.scrollabale-xs {
    overflow-y: scroll;
    width: 110.35%;
    margin-left: -5.175%;
    box-sizing: border-box;
    padding: 0 5.175%;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
  }
  .models-list-block .models-list-block-holder .models-list.scrollabale-xs::-webkit-scrollbar {
    display: none !important;
  }
  .models-list-block .models-list-block-holder .models-list.scrollabale-xs.tighter .models-list-holder {
    width: 894px;
  }
  .models-list-block .models-list-block-holder .models-list.scrollabale-xs .models-list-holder {
    column-count: 6;
    width: 1236px;
  }
  .models-list-block .models-list-block-holder .models-list.xs-6-col .models-list-holder {
    column-count: 6;
  }
  .models-list-block .models-list-block-holder .models-list.xs-2-col .models-list-holder {
    column-count: 2;
    column-gap: 14px;
  }
  .models-list-block .models-list-block-holder .models-list.tighter .models-list-holder .model-item {
    margin: 0 0 10px;
  }
  .models-list-block .models-list-block-holder .models-list.tighter .models-list-holder .model-item .model-item-holder {
    padding-left: 28px;
  }
  .models-list-block .models-list-block-holder .models-list.tighter .models-list-holder .model-item .model-item-holder .name a {
    font-size: 10px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item {
    margin: 0 0 15px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder {
    padding-left: 23px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .letter {
    font-size: 18px;
    line-height: 120%;
    top: 4px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a {
    font-size: 14px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .icon {
    margin-right: 10px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .icon img {
    width: 30px;
    height: 30px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .name {
    width: calc(100% - 30px - 10px);
  }
  .models-list-block .models-list-block-holder .action {
    width: 100%;
    margin: 12px 0 0;
  }
  .models-list-block .models-list-block-holder .action .btn {
    width: 100%;
    text-align: center;
  }
}
.text-block {
  display: inline-block;
  width: 100%;
}
.text-block .text-block-holder {
  display: inline-block;
  width: 66%;
  color: #979797;
  padding: 46px 0 46px;
}
.text-block .text-block-holder.full {
  width: 100%;
}
@media (max-width: 1469px) {
  .text-block .text-block-holder {
    padding: 44px 0 44px;
    width: 88%;
  }
}
@media (max-width: 1199px) {
  .text-block .text-block-holder {
    padding: 28px 0 28px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .text-block .text-block-holder {
    padding: 40px 0 40px;
  }
}
.common-heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 40px;
}
.common-heading h2, .common-heading .h2 {
  float: left;
  max-width: 80%;
}
.common-heading .action {
  float: right;
  max-width: 20%;
  text-align: right;
  padding: 11px 0 0;
}
.common-heading .action a {
  float: right;
}
.common-heading .action a .text {
  float: left;
}
.common-heading .action a .icon {
  float: left;
  width: 17px;
  height: 12px;
  margin-left: 9px;
  padding: 3px 0 0;
}

.slider-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 40px;
}
.slider-heading .controlls {
  margin-right: -7.5px;
}
.slider-heading .controlls .prev, .slider-heading .controlls .next {
  float: left;
  margin: 0 7.5px;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  transform: none;
}
.slider-heading .controlls .next {
  float: left;
}
@media (max-width: 1199px) {
  .common-heading {
    margin: 0 0 28px;
  }
  .common-heading .action {
    padding: 7px 0 0;
  }
  .common-heading .action a .icon {
    padding: 2px 0 0;
  }
  .slider-heading {
    margin: 0 0 30px;
  }
}
@media (max-width: 767px) {
  .common-heading h2, .common-heading .h2 {
    max-width: 65%;
  }
  .common-heading .action {
    max-width: 35%;
    font-size: 12px;
    line-height: 120%;
    padding: 5px 0 0;
  }
  .common-heading .action a .icon {
    margin-left: 6px;
  }
  .common-heading .action a .icon svg {
    width: 12px;
    height: auto;
  }
}
.services-block {
  display: inline-block;
  width: 100%;
  background-color: #F2F5F7;
  padding: 79px 0 78px;
}
.services-block .services-block-holder {
  display: inline-block;
  width: 100%;
}
.services-block .services-block-holder > .action {
  display: inline-block;
  width: 100%;
  margin: 28px 0 0;
}
.services-block .services-block-holder > .action .btn {
  text-align: center;
  width: 100%;
  padding: 13px 14px;
}
.services-block .services-block-holder .services-grid {
  display: inline-block;
  width: 100%;
}
.services-block .services-block-holder .services-grid .services-grid-holder {
  width: 100%;
  display: grid;
  grid-auto-rows: 412px;
  grid-template-columns: 49% 1fr 1fr;
  grid-template-rows: 412px 412px;
  gap: 30px 30px;
  grid-template-areas: "big . ." "big . .";
}
.services-block .services-block-holder .services-grid .services-grid-holder .big {
  grid-area: big;
}
.services-block .services-block-holder .services-grid .services-grid-holder .service-item {
  width: 100%;
  height: 100%;
}

.service-item.big .service-item-holder .image {
  height: 630px;
}
.service-item.big .service-item-holder .desc .desc-holder .name {
  font-size: 28px;
  line-height: 120%;
}

.service-item {
  display: inline-block;
  width: 100%;
  background-color: white;
}
.service-item .service-item-holder {
  display: inline-block;
  width: 100%;
}
.service-item .service-item-holder .image {
  float: left;
  width: 100%;
  height: 200px;
}
.service-item .service-item-holder .image a {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.service-item .service-item-holder .image a:hover img {
  transform: scale(1.1);
  transition: all 300ms;
}
.service-item .service-item-holder .image a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}
.service-item .service-item-holder .desc {
  float: left;
  width: 100%;
}
.service-item .service-item-holder .desc .desc-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 30px 25px;
}
.service-item .service-item-holder .desc .desc-holder .name {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  margin: 0 0 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
}
.service-item .service-item-holder .desc .desc-holder .name a {
  color: #000;
}
.service-item .service-item-holder .desc .desc-holder .name a:hover {
  color: #CF2127;
}
.service-item .service-item-holder .desc .desc-holder .price {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  line-height: 140%;
  color: #979797;
  margin: 0 0 21px;
}
.service-item .service-item-holder .desc .desc-holder > .text {
  display: inline-block;
  width: 100%;
  height: 44px;
  font-size: 16px;
  line-height: 140%;
  color: #979797;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
  margin: 0 0 17px;
}
.service-item .service-item-holder .desc .desc-holder .action {
  display: inline-block;
  width: 100%;
}
.service-item .service-item-holder .desc .desc-holder .action a {
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}
.service-item .service-item-holder .desc .desc-holder .action a .text {
  float: left;
}
.service-item .service-item-holder .desc .desc-holder .action a .icon {
  float: left;
  width: 17px;
  height: 11px;
  margin: 0 0 0 11px;
  padding: 6px 0 0;
}
@media (max-width: 1469px) {
  .services-block {
    padding: 61px 0 61px;
  }
  .services-block .services-block-holder .services-grid .services-grid-holder {
    gap: 20px 20px;
    grid-template-columns: 49.1% 1fr 1fr;
    grid-template-rows: 400px 400px;
    grid-auto-rows: 400px;
  }
  .service-item.big .service-item-holder .image {
    height: 609px;
  }
  .service-item .service-item-holder .desc .desc-holder {
    padding: 25px 25px 0;
  }
  .service-item .service-item-holder .desc .desc-holder .name {
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
}
@media (max-width: 1199px) {
  .services-block {
    padding: 49px 0 59px;
    overflow: hidden;
    margin: 0 0 -3px;
  }
  .services-block .services-block-holder .services-grid .slick-slider {
    width: calc(100% + 10px);
    margin-left: -5px;
    padding: 0 30.8% 0 0;
    box-sizing: border-box;
  }
  .services-block .services-block-holder .services-grid .slick-slider .slick-list {
    overflow: visible;
  }
  .services-block .services-block-holder .services-grid .slick-slider .slick-list .slick-track .slick-slide {
    padding: 0 5px;
  }
  .services-block .services-block-holder .services-grid .services-grid-holder {
    display: inline-block;
  }
  .service-item.big .service-item-holder .image {
    height: 200px;
  }
  .service-item.big .service-item-holder .desc .desc-holder .name {
    font-size: 16px;
    line-height: 120%;
  }
  .service-item .service-item-holder .desc .desc-holder {
    padding: 25px 25px 20px;
  }
  .service-item .service-item-holder .desc .desc-holder .name {
    font-size: 16px;
    line-height: 120%;
    margin: 0 0 5px;
  }
  .service-item .service-item-holder .desc .desc-holder .price {
    font-size: 14px;
    margin: 0 0 15px;
  }
  .service-item .service-item-holder .desc .desc-holder > .text {
    font-size: 14px;
    margin: 0 0 10px;
  }
  .service-item .service-item-holder .desc .desc-holder .action a {
    font-size: 14px;
  }
  .service-item .service-item-holder .desc .desc-holder .action a .icon {
    padding: 3px 0 0;
  }
}
@media (max-width: 767px) {
  .services-block {
    padding: 39px 0 49px;
  }
  .services-block .services-block-holder .services-grid .slick-slider {
    width: calc(100% + 15px);
    margin-left: -7.5px;
    padding: 0 7.5% 0 0;
  }
  .services-block .services-block-holder .services-grid .slick-slider .slick-list .slick-track .slick-slide {
    padding: 0 7.5px;
  }
  .service-item.big .service-item-holder .desc .desc-holder .name {
    font-size: 14px;
  }
  .service-item .service-item-holder .desc .desc-holder {
    padding: 20px 20px 15px;
  }
  .service-item .service-item-holder .desc .desc-holder .name {
    font-size: 14px;
    margin: 0 0 5px;
  }
  .service-item .service-item-holder .desc .desc-holder .price {
    font-size: 12px;
    margin: 0 0 15px;
  }
  .service-item .service-item-holder .desc .desc-holder > .text {
    font-size: 12px;
    margin: 0 0 10px;
    height: 33px;
    margin: 0 0 16px;
  }
  .service-item .service-item-holder .desc .desc-holder .action a {
    font-size: 12px;
  }
  .service-item .service-item-holder .desc .desc-holder .action a .icon {
    padding: 3px 0 0;
  }
  .service-item .service-item-holder .desc .desc-holder .action a .icon svg {
    width: 12px;
    height: auto;
  }
}