@media (max-width: $screen-sm) {
    // sm-view
    .common-heading { 
        h2, .h2 { 
            max-width: 65%;
        }

        .action {
            max-width: 35%;
            font-size: 12px;
            line-height: 120%;
            padding: 5px 0 0;
            a {
                .text {

                }

                .icon {
                    margin-left: 6px;
                    svg {
                        width: 12px;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
