.services-block { 
    display: inline-block;
    width: 100%;
    background-color: $bg-secondary;
    padding: 79px 0 78px;
	.services-block-holder { 
        display: inline-block;
        width: 100%;
        >.action {
            display: inline-block;
            width: 100%;
            margin: 28px 0 0;
            .btn {
                text-align: center;
                width: 100%;
                padding: 13px 14px;
            }
        }
		.services-grid { 
            display: inline-block;
            width: 100%;
			.services-grid-holder { 
                width: 100%;
                display: grid; 
                grid-auto-rows: 412px; 
                grid-template-columns: 49% 1fr 1fr;
                grid-template-rows: 412px 412px; 
                gap: 30px 30px; 
                grid-template-areas: 
                  "big . ."
                  "big . ."; 
                .big { grid-area: big; }

                .service-item { 
                    width: 100%;
                    height: 100%;
                }
				
			}
		}
	}
}

.service-item.big {
    .service-item-holder { 
        .image { 
            height: 630px;
        }

        .desc { 
            .desc-holder { 
                .name { 
                    font-size: 28px;
                    line-height: 120%;
                }
            }
        }
    }
}
.service-item { 
    display: inline-block;
    width: 100%;
    background-color: white;
    .service-item-holder { 
        display: inline-block;
        width: 100%;
        .image { 
            float: left;
            width: 100%;
            height: 200px;
            a { 
                display: inline-block;
                width: 100%;
                height: 100%;
                overflow: hidden;
                &:hover {
                    img {
                        transform: scale(1.1);
                        transition: all 300ms;
                    }
                }
                img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 300ms;
                }
            }
        }

        .desc { 
            float: left;
            width: 100%;
            .desc-holder { 
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding: 30px 30px 25px;
                .name { 
                    display: inline-block;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 120%;
                    margin: 0 0 3px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -moz-box;
                    -moz-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    a { 
                        color: $gray-base;
                        &:hover {
                            color: $brand-primary;
                        }
                    }
                }

                .price { 
                    display: inline-block;
                    width: 100%;
                    font-size: 16px;
                    line-height: 140%;
                    color: $gray;
                    margin: 0 0 21px;
                }

                >.text { 
                    display: inline-block;
                    width: 100%;
                    height: 44px;
                    font-size: 16px;
                    line-height: 140%;
                    color: $gray;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -moz-box;
                    -moz-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    box-orient: vertical;
                    margin: 0 0 17px;
                }

                .action { 
                    display: inline-block;
                    width: 100%;
                    a { 
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 140%;
                        .text { 
                            float: left;
                        }

                        .icon { 
                            float: left;
                            width: 17px;
                            height: 11px;
                            margin: 0 0 0 11px;
                            padding: 6px 0 0;
                            svg {

                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/services";
@import "../../media/mobile/includes/index/services";
