@media (max-width: $screen-sm) {
    // sm-view
    .active-filters { 
        .active-filters-holder { 
            width: calc(100% + 10px);
            .filter-item {
                margin-right: 4px;
                margin-bottom: 4px;
                .filter-item-holder { 
                    padding: 6px 7px 6px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
