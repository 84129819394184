@media (max-width: $screen-lg) {
    // lg-md view
    .work-steps-block { 
        padding: 68px 0 46px;
        .work-steps-block-holder { 
            .heading { 

            }

            .steps { 
                flex-wrap: wrap;
                width: calc(100% + 20px);
                margin-left: -10px;
                .step { 
                    width: 50%;
                    padding: 0 10px;
                    margin: 0 0 20px;
                    .step-holder {
                        display: flex;
                        align-items: center;
                        .number { 
                            width: 44px;
                            margin: 0;
                        }

                        .text {
                            width: calc(100% - 44px);
                            box-sizing: border-box;
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .work-steps-block { 
        padding: 48px 0 39px;
        .work-steps-block-holder { 
            .heading { 
                margin: 0 0 30px;
            }

            .steps { 
                width: calc(100% + 10px);
                margin-left: -5px;
                .step {
                    padding: 0 5px;
                    margin: 0 0 10px;
                    .step-holder {
                        .number { 
                            width: 39px;
                            .number-holder { 
                                width: 39px;
                                height: 39px;
                                line-height: 39px;
                                font-size: 24px;
                            }
                        }

                        .text {
                            width: calc(100% - 39px);
                            .text-holder { 
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
