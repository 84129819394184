@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-block {
        .catalog-block-holder {
            width: 100%;
            margin: 0;
            .col {
                width: 100%;
                padding: 0;
                margin: 0 0 35px;
            }

            .main-content {
                width: 100%;
                padding: 0;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .catalog-block {
        .catalog-block-holder {
            .col {
                margin: 0 0 26px;
            }
        }
    }
}
