@media (max-width: $screen-lg) {
    // lg-md view
   
}

@media (max-width: $screen-md) {
    // md-view
    .items-list {
        margin: 0 0 29px;
        .items-list-holder {
            .catalog-item {
                margin: 0 0 25px;
                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
    
    .catalog-item { 
        .catalog-item-holder { 
            padding: 0 0 30px;
            border: none;
            border-bottom: 1px solid $gray-lighter;
            .image { 
                width: 124px;
                height: 90px;
            }

            .about-item { 
                padding-left: 20px;
                width: calc(100% - 124px);
                .about-item-holder { 
                    .item-desc { 
                        width: calc(100% - 190px);
                        padding-right: 30px;
                        .item-desc-holder { 
                            >.name {
                                font-size: 16px;
                            }

                            .waranty { 
                                .value {
                                    font-size: 10px;
                                }
                            }

                            .parameters { 
                                .parameters-holder { 
                                    .param {
                                        .param-holder { 
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .item-order { 
                        width: 190px;
                        .item-order-holder { 
                            .price { 
                                margin: 0 0 9px;
                                .value { 

                                }

                                .add-price { 
                                    font-size: 12px;
                                }
                            }

                            .action { 
                                .btn {

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
