@media (max-width: $screen-lg) {
    // lg-md view
    .partner-promo { 
        .partner-promo-holder { 
            .partner-desc { 
                .image {
                    width: 450px;
                }

                .text { 
                    width: calc(100% - 450px);
                    padding-left: 21px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .partner-promo {
        padding: 51px 0 41px;
        .partner-promo-holder { 
            .partner-desc {
                .image {

                }

                .text { 
                    width: 100%;
                    padding-left: 0;
                    .text-holder { 
                        .inner-text { 

                        }

                        .action {
                            .btn {
                                padding: 12px 46px;
                            }
                        }
                    }
                }
            }
        }
    }
}
