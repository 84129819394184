@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .pagination {
        margin: 4px 0 0;
        ul {
            margin-right: -9px;
            >li {
                &.middle {
                    a {
                        line-height: 50px;
                    }
                }
                a {
                    width: 30px;
                    height: 30px;
                    font-size: 12px;
                    line-height: 30px;
                }
            }
        }
    }
}
