@media (max-width: $screen-sm) {
    // sm-view
    .additional-services-block { 
        padding: 39px 0 58px;
        .additional-services-block-holder { 
            .heading { 

            }

            .slick-slider {
                width: calc(100% + 15px);
                margin-left: -7.5px;
                box-sizing: border-box;
                padding-right: 6.7%;
                .slick-list {
                    overflow: visible;
                    .slick-slide {
                        padding: 0 7.5px;
                    }
                }
            }

            .services-slider { 

                .service-item { 
    
                    .service-item-holder { 
    
                        .image { 
    
                            a { 
    
                                img { 
    
                                }
                            }
                        }
    
                        .desc { 
    
                            .desc-holder { 
                                padding: 20px 20px 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
