@media (max-width: $screen-sm) {
    // sm-view
    .breadcrumbs {
        .breadcrumbs-holder {
            ul.breadcrumbs-list {
                li {
                    font-size: 10px;
                    margin-right: 1px;
                    &.sep {
                        &:after {
                            height: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
