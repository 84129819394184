.glass-form { 
    display: inline-block;
    width: 100%;
    padding: 50px 0 50px;
    &.padding-to-margin {
        padding: 0;
        margin: 0 0 34px;
    }
	.glass-form-holder { 
        display: inline-block;
        width: 100%;
		.heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 41px;
			.h2, h2 { 

			}
		}

		.selection-form { 
            display: inline-block;
            width: 100%;
            background-color: $bg-secondary;
            box-sizing: border-box;
            padding: 40px;
			form { 
                display: inline-block;
                width: calc(100% + 60px);
                margin-left: -30px;
				.fields { 
                    width: calc(100% - 395px);
                    float: left;
                    box-sizing: border-box;
                    padding: 0 30px;
					.fields-holder { 
                        display: inline-block;
                        width: 100%;
						.form-group { 
                            width: calc(100% + 10px);
                            margin-left: -5px;
							.form-col {     
                                float: left;
                                box-sizing: border-box;
                                padding: 0 5px;
								.form-field { 
                                    display: inline-block;
                                    width: 100%;
                                    margin: 0;
									.input { 

										.name { 

										}

										input { 

										}

										
									}
								}
							}
						}
					}
				}

				.action-col { 
                    width: 395px;
                    float: left;
                    box-sizing: border-box;
                    padding: 0 30px;
					.action-col-holder { 
                        display: inline-block;
                        width: 100%;
						.action { 
                            display: inline-block;
                            width: 100%;
                            margin: 0;
                            padding: 0;
							.submit { 
                                display: inline-block;
                                width: 100%;
								.btn { 
                                    display: inline-block;
                                    width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/glass_form";
@import "../../media/mobile/includes/index/glass_form";
