@media (max-width: $screen-sm) {
    // sm-view
    .pagination {
        margin: 33px 0 0;
        ul {
            margin-right: -2.5px;
            width: calc(100% + 5px);
            >li {
                margin: 0 2.5px;
                &.middle {
                    a {

                    }
                }
                a {
                   
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
