@media (max-width: $screen-sm) {
    // sm-view
    .partner-promo {
        padding: 39px 0 41px;
        .partner-promo-holder { 
            .partner-desc {
                .image {

                }

                .text { 
                    .text-holder { 
                        .inner-text { 

                        }

                        .action {
                            .btn {
                                width: 100%;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
