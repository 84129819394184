@media (max-width: $screen-lg) {
    // lg-md view
    .glass-type-select {
        margin: 0 0 38px;
        .glass-type-select-holder { 
            .glass-types { 
                width: calc(100% + 20px);
                margin-left: -10px;
                .type { 
                    width: 25%;
                    padding: 0 10px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .glass-type-select {
        margin: 0 0 26px;
        overflow-y: auto; 
        scrollbar-width: none !important;
        -ms-overflow-style: none !important;
        .glass-type-select-holder {
            
            &::-webkit-scrollbar {
                display: none !important;
            }
            .glass-types { 
                display: flex;
                flex-wrap: nowrap;
                width: calc(200% + 10px);
                margin-left: -5px;
                .type { 
                    width: auto;
                    padding: 0 5px;
                    .type-holder { 
                        a {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
