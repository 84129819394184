@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .breadcrumbs {
        .breadcrumbs-holder {
            ul.breadcrumbs-list {
                li {
                    font-size: 12px;
                }
            }
        }
    }
}
