@media (max-width: $screen-lg) {
    // lg-md view
    .service-details {
        margin: 0 0 47px;
        .service-details-holder { 
            .image-block { 
                .image-block-holder { 
                    .order-block { 
                        width: 65%;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .service-details {
        margin: 0 0 20px;
        .service-details-holder { 
            .image-block { 
                margin: 0 0 30px;
                .image-block-holder { 
                    .image { 
                        height: 400px;
                        img {

                        }
                    }

                    .order-block { 
                        width: 83%;
                        margin: -55px auto 0;
                        .order-block-holder {
                            .pricing {
                                .timing {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }

            .desc { 
                .desc-holder { 
                    margin: 0 0 18px;
                    h3, .h3 {
                        margin: 0 0 21px;
                    }

                    p {

                    }
                }
            }
        }
    }
}
