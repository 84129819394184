.work-examples-block { 
    display: inline-block;
    width: 100%;
    overflow: hidden;
    margin: 0 0 62px;
	.work-examples-block-holder { 
        display: inline-block;
        width: 100%;

        .slick-slider {
            padding-right: 0%;
            box-sizing: border-box;
            .slick-list {
                overflow: visible;
                .slick-slide {
                    
                }
            }
        }

		.exmaples-slider { 
			.example-image { 
                display: inline-block;
                width: 100%;
                height: 470px;
				.example-image-holder { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
					a { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        &:hover {
                            img {
                                transform: scale(1.1);
                                transition: all 300ms;
                            }
                        }
						img { 
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            transition: all 300ms;
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/examples_slider";
@import "../../media/mobile/includes/catalog/examples_slider";
