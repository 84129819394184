.partner-promo { 
    display: inline-block;
    width: 100%;
    padding: 65px 0 41px;
	.partner-promo-holder { 
        display: inline-block;
        width: 100%;
		.partner-desc { 
            display: flex;
            width: 100%;
			.image { 
                width: 470px;
                height: auto;
				.image-holder { 
                    width: 100%;
                    height: 100%;
					img { 
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
					}
				}
			}

			.text { 
                width: calc(100% - 470px);
                box-sizing: border-box;
                padding-left: 30px;
				.text-holder { 
                    width: 100%;
					.inner-text { 
                        width: 100%;
						h2, .h2 { 
                            margin: 0 0 19px;
						}

						p { 
                            color: $gray;
						}
					}

					.action { 
                        width: 100%;
                        margin: 31px 0 0;
						.btn { 
                            padding: 13px 34px;
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/partner_promo";
@import "../../media/mobile/includes/catalog/partner_promo";
