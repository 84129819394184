@media (max-width: $screen-lg) {
    // lg-md view
    .work-examples-block { 
        .work-examples-block-holder { 

            .slick-slider {
                padding-right: 13.8%;
                width: calc(100% + 20px);
                margin-left: -10px;
                .slick-list {
                    .slick-slide {
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .work-examples-block { 
        margin: 0 0 46px;
        .work-examples-block-holder { 

            .slick-slider {
                padding-right: 30.8%;
                width: calc(100% + 10px);
                margin-left: -5px;
                .slick-list {
                    .slick-slide {
                        padding: 0 5px;
                    }
                }
            }

            .exmaples-slider { 
                .example-image {
                    height: 270px;
                }
            }
        }
    }
}
