@media (max-width: $screen-sm) {
    // sm-view
    .work-steps-block { 
        padding: 38px 0 32px;
        .work-steps-block-holder { 
            .heading { 

            }

            .steps { 
                width: 100%;
                margin: 0;
                .step { 
                    width: 100%;
                    padding: 0;
                    .step-holder {
                        padding: 20px 20px;
                        .number {

                        }

                        .text {
                            padding-left: 15px;
                            .text-holder {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
