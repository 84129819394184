.common-heading { 
    display: inline-block;
    width: 100%;
    margin: 0 0 40px;
    h2, .h2 { 
        float: left;
        max-width: 80%;
    }

    .action {
        float: right;
        max-width: 20%;
        text-align: right;
        padding: 11px 0 0;
        a {
            float: right;
            .text {
                float: left;
            }

            .icon {
                float: left;
                width: 17px;
                height: 12px;
                margin-left: 9px;
                padding: 3px 0 0;
            }
        }
    }
}

.slider-heading { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0 40px;
    .text { 
        h2, .h2 { 

        }
    }

    .controlls { 
        margin-right: -7.5px;
        .prev, .next { 
            float: left;
            margin: 0 7.5px;
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            transform: none;
            svg { 

                path { 

                }
            }
        }

        .next { 
            float: left;
            svg { 

                path { 

                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/heading";
@import "../../media/mobile/includes/common/heading";
