@media (max-width: $screen-sm) {
    // sm-view
    .services-list { 
        margin: 0 0 22px;
        .services-list-holder { 
            .services-categories { 
                width: 100%;
                margin-left: 0;
                .category-col { 
                    width: 100%;
                    padding: 0;
                    margin: 0 0 29px;
                    .category-col-holder { 
                        .heading {
                            margin: 0 0 9px;
                        }

                        .services-links { 
                            ul {
                                li {
                                    font-size: 10px;
                                    margin: 0 0 4px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
