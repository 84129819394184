.items-list {
    display: inline-block;
    width: 100%;
    margin: 0 0 12px;
    .items-list-holder {
        display: inline-block;
        width: 100%;
        .catalog-item {
            float: left;
            width: 100%;
            margin: 0 0 -3px;
        }
    }
}

.catalog-item { 
    display: inline-block;
    width: 100%;
	.catalog-item-holder { 
        display: inline-block;
        width: 100%;
        border: 1px solid $gray-lighter;
        box-sizing: border-box;
        padding: 30px;
		.image { 
            float: left;
            width: 170px;
            height: 130px;
			.image-holder { 
                width: 100%;
                height: 100%;
				a { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
					img { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
					}
				}
			}
		}

		.about-item { 
            float: left;
            width: calc(100% - 170px);
            box-sizing: border-box;
            padding-left: 30px;
			.about-item-holder { 
                display: inline-block;
                width: 100%;
				.item-desc { 
                    float: left;
                    width: calc(100% - 280px);
                    box-sizing: border-box;
                    padding-right: 30px;
					.item-desc-holder { 
                        display: inline-block;
                        width: 100%;
						>.name { 
                            display: inline-block;
                            width: 100%;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 120%;
                            text-align: left;
                            margin: 0 0 3px;
							a { 
                                color: $gray-base;
                                &:hover {
                                    color: $brand-primary;
                                }
							}
						}

						.waranty { 
                            display: inline-block;
                            width: 100%;
                            margin: 0 0 16px;
							.value { 
                                float: left;
                                margin-right: 4px;
                                background-color: $bg-secondary;
                                padding: 7px;
                                text-transform: uppercase;
                                font-size: 12px;
                                font-weight: 600;
                                line-height: 120%;
                                text-align: left;
							}
						}

						.parameters { 
                            display: inline-block;
                            width: 100%;
							.parameters-holder { 
                                display: flex;
                                width: calc(100% + 15px);
                                margin-left: -7.5px;
								.param { 
                                    width: 25%;
                                    box-sizing: border-box;
                                    padding: 0 7.5PX;
									.param-holder { 
                                        display: inline-block;
                                        width: 100%;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 140%;
                                        text-align: left;
										.name { 
                                            display: inline-block;
                                            width: 100%;
                                            color: $gray;
                                            margin: 0 0 5px;
										}

										.value { 
                                            display: inline-block;
                                            width: 100%;
										}
									}
								}
							}
						}
					}
				}

				.item-order { 
                    float: right;
                    width: 280px;
					.item-order-holder { 
                        display: inline-block;
                        width: 100%;
						.price { 
                            display: inline-block;
                            width: 100%;
                            text-align: right;
                            margin: 0 0 20px;
							.value { 
                                display: inline-block;
                                width: 100%;
							}

							.add-price { 
                                display: inline-block;
                                width: 100%;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 140%;
                                color: $gray;
                                margin: 5px 0 0;
							}
						}

						.action { 
                            display: inline-block;
                            width: 100%;
							.btn { 
                                display: inline-block;
                                width: 100%;
                                text-align: center;
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/items";
@import "../../media/mobile/includes/catalog/items";
