@media (max-width: $screen-sm) {
    // sm-view
    .catalog-block {
        .catalog-block-holder {
            .col {
                margin: 0 0 17px;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
