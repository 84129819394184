.catalog-block {
    display: inline-block;
    width: 100%;
    .catalog-block-holder {
        display: inline-block;
        width: calc(100% + 30px);
        margin-left: -15px;
        .col {  
            float: left;
            width: 375px;
            box-sizing: border-box;
            padding: 0 15px;
            .col-holder {
                display: inline-block;
                width: 100%;
            }
        }

        .main-content {
            float: left;
            width: calc(100% - 375px);
            box-sizing: border-box;
            padding: 0 15px;
            .main-content-holder {
                display: inline-block;
                width: 100%;
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/catalog_block";
@import "../../media/mobile/includes/catalog/catalog_block";
