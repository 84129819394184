.text-block { 
    display: inline-block;
    width: 100%;
	.text-block-holder { 
        display: inline-block;
        width: 66%;
        color: $gray;
        padding: 46px 0 46px;
		&.full {
			width: 100%;
		}
		p { 

		}

		ul, ol { 

			li { 

			}
		}

		ol { 

			li { 

			}
		}
	}
}

@import "../../media/tablet/includes/index/text";
@import "../../media/mobile/includes/index/text";
