@media (max-width: $screen-sm) {
    // sm-view
    .services-block { 
        padding: 39px 0 49px;
        .services-block-holder { 
            .services-grid { 

                .slick-slider {
                    width: calc(100% + 15px);
                    margin-left: -7.5px;
                    padding: 0 7.5% 0 0;
                    .slick-list {
                        .slick-track {
                            .slick-slide {
                                padding: 0 7.5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .service-item.big {
        .service-item-holder { 
            .image {
                
            }

            .desc { 
                .desc-holder { 
                    .name { 
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .service-item { 
        .service-item-holder { 
            .image { 

            }

            .desc {
                .desc-holder {
                    padding: 20px 20px 15px;
                    .name {
                        font-size: 14px;
                        margin: 0 0 5px;
                    }

                    .price { 
                        font-size: 12px;
                        margin: 0 0 15px;
                    }

                    >.text { 
                        font-size: 12px;
                        margin: 0 0 10px;
                        height: 33px;
                        margin: 0 0 16px;
                    }

                    .action { 
                        a {
                            font-size: 12px;
                            .icon { 
                                padding: 3px 0 0;
                                svg {
                                    width: 12px;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
