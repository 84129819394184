.service-details { 
    display: inline-block;
    width: 100%;
    margin: 0 0 65px;
	.service-details-holder { 
        display: inline-block;
        width: 100%;
		.image-block { 
            display: inline-block;
            width: 100%;
            margin: 0 0 40px;
			.image-block-holder { 
                display: inline-block;
                width: 100%;
				.image { 
                    display: inline-block;
                    width: 100%;
                    height: 540px;
                    position: relative;
                    z-index: 1;
					img { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
					}
				}

                .order-block { 
                    width: 49%;
                    margin: -61px auto 0;
                    position: relative;
                    z-index: 2;
                    .order-block-holder { 
                        width: 100%;
                        background-color: $brand-primary;
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 30px 40px;
                        .pricing { 
                            
                            .price { 
                                margin: 0 0 4px;
                            }
                
                            .timing { 
                                color: #E79093;
                                font-size: 14px;
                                line-height: 140%;
                            }
                        }
                
                        .action { 
                            width: 280px;
                            .btn { 
                                width: 100%;
                                text-align: center;
                                background-color: transparent;
                                color: white;
                                border-color: white;
                                &:hover {
                                    color: $brand-primary;
                                    background-color: white;
                                }
                            }
                        }
                    }
                }
			}
		}

		.desc { 
            display: inline-block;
            width: 100%;
			.desc-holder { 
                display: inline-block;
                width: 100%;
				h3, .h3 { 
                    margin: 0 0 30px;
					br { 

					}
				}

				p { 
                    color: $gray;
                    margin: 0 0 15px;
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/services_group";
@import "../../media/mobile/includes/catalog/services_group";
