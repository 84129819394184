@media (max-width: $screen-lg) {
    // lg-md view
    .active-filters { 

    }
}

@media (max-width: $screen-md) {
    // md-view
    .active-filters { 
        margin: 0 0 12px;
        .active-filters-holder { 
            .filter-item { 
                .filter-item-holder { 
                    .name {
                        font-size: 10px;
                    }

                    .action { 
                        a {
                            
                        }
                    }
                }
            }
        }
    }
}
