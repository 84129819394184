.additional-services-block { 
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    background-color: $bg-secondary;
    box-sizing: border-box;
    padding: 66px 0 64px;
	.additional-services-block-holder { 
        display: inline-block;
        width: 100%;

		.services-slider { 

			.service-item { 

				.service-item-holder { 

					.image { 

						a { 

							img { 

							}
						}
					}

					.desc { 

						.desc-holder { 

							.name { 
                                -webkit-line-clamp: 1;
                                line-clamp: 1
								a { 

								}
							}

							.price { 

							}

							.text { 

							}

							.action { 

								a { 

									.text { 

									}

									.icon { 

										svg { 

											path { 

											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/additional_services";
@import "../../media/mobile/includes/catalog/additional_services";
