.pagination {
    display: inline-block;
    width: 100%;
    margin: 25px 0 0;
	ul {
        float: right;
        width: auto;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-right: -19px;
        >li {
            float: left;
            margin: 0 5px;
            &.next, &.prev {
                a {
                    background: none;
                    &:hover {
                        background: none;
                        color: $brand-primary;
                    }
                    svg {
                        width: 17px;
                        height: 12px;
                        vertical-align: middle;
                    }
                }
            }
            &.next {
                a {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
            &.middle {
                a {
                    background: none;
                    width: auto;
                    vertical-align: bottom;
                    line-height: 60px;
                    overflow: hidden;
                    &:hover {
                        background: none;
                        color: $brand-primary;
                    }
                }
            }
            &.current {
                a {
                    background-color: $brand-primary;
                    color: white;
                    pointer-events: none;
                    touch-action: none;
                }
            }
            a {
                display: inline-block;
                width: 40px;
                height: 40px;
                border: none;
                box-sizing: border-box;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 40px;
                text-align: center;
                color: $gray-base;
                text-decoration: none;
                border-radius: 0;
                transition: all 300ms;
                background-color: $bg-secondary;
                &:hover {
                    background-color: $brand-primary;
                    color: white;
                    transition: all 300ms;
                }
            }
        }
	}
}

@import "../media/tablet/includes/pagination";
@import "../media/mobile/includes/pagination";
