@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-filter { 
        .catalog-filter-holder { 
            form {
                .form-group { 

                    .form-col { 
                        &.col-1 {
                            width: 50%;
                        }

                        &.col-2 {
                            width: 33.333%;
                        }
                        
                    }
                }

                .action { 
                    .action-holder {
                        .submit { 
                            width: 280px;
                            float: right;
                            .btn {
                                
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .catalog-filter { 
        .catalog-filter-holder { 
            form {
                .form-group { 
                    width: calc(100% + 10px);
                    margin-left: -5px;
                    .form-col { 
                        padding: 0 5px;                        
                    }
                }

                .form-field { 
                    margin-bottom: 13.5px;
                }

                .action { 
                    .action-holder {
                        .submit { 
                            width: 191px;
                            float: right;
                            .btn {
                                
                            }
                        }
                    }
                }
            }
        }
    }
}
