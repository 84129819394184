@media (max-width: $screen-sm) {
    // sm-view
    .service-details {
        margin: 0 0 9px;
        .service-details-holder { 
            .image-block { 
                .image-block-holder { 
                    .image { 

                    }

                    .order-block { 
                        margin: 23px 0 0;
                        width: 100%;
                        .order-block-holder { 
                            background-color: transparent;
                            color: black;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 0;
                            .pricing { 
                                display: flex;
                                align-items: center;
                                margin: 0 0 22px;
                                .price {    
                                    margin: 0;
                                }
                    
                                .timing { 
                                    color: $gray;
                                    font-size: 10px;
                                    margin: 0 0 0 12px;
                                }
                            }

                            .action { 
                                width: 100%;
                                .btn { 
                                    width: 100%;
                                    text-align: center;
                                    background-color: $brand-primary;
                                    color: white;
                                    border-color: $brand-primary;
                                    &:hover {
                                        color: white;
                                        background-color: $brand-primary-accent;
                                        border-color: $brand-primary-accent;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .desc { 
                .desc-holder { 
                    h3, .h3 {
                        margin: 0 0 23px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
