.breadcrumbs {
    display: inline-block;
    width: 100%;
    margin-top: 27px;
    margin-bottom: 27px;
    .breadcrumbs-holder {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        ul.breadcrumbs-list {
            display: inline-block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                display: inline;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 140%;
                margin-right: 0;
                color: $gray-base;
                a {
                    color: $gray-light;
                    text-decoration: none;
                    transition: all 300ms;
                    &:hover {
                        color: $brand-primary;
                        transition: all 300ms;
                    }
                }
                span {

                }
                &.sep {
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        width: 0;
                        height: 15px;
                        border-left: 1px solid $gray-light;
                        position: absolute;
                        top: 2px;
                        left: 50%;
                        transform: translateX(-2px);
                    }
                }
            }
        }
    }
}

@import "../media/tablet/includes/breadcrumbs";
@import "../media/mobile/includes/breadcrumbs";
