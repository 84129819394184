@media (max-width: $screen-sm) {
    // sm-view
    .glass-type-select { 
        margin: 0 0 13px;
        .glass-type-select-holder { 
            .glass-types { 
                width: calc(300% + 10px);
                .type { 
                    .type-holder { 
                        a {
                            font-size: 10px;
                            padding: 10px 15px;
                            .icon {
                                width: 20px;
                                height: 20px;
                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .text { 
                                width: calc(100% - 20px);
                                padding-left: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
