.catalog-filter { 
    display: inline-block;
    width: 100%;
	.catalog-filter-holder { 
        display: inline-block;
        width: 100%;
        .heading {
            margin: 0 0 19px;
        }
        
		form { 

			.form-group { 

				.form-col { 

					
				}
			}

            .form-field { 
                margin-bottom: 18.5px;
                .input { 

                    .name { 

                    }

                    input { 

                    }

                    .addon { 

                        .btn { 

                            .plus { 

                            }

                            .search { 

                            }
                        }
                    }

                    .results { 

                        .results-holder { 

                            .inner-results { 

                                .item { 

                                    a { 

                                    }
                                }
                            }
                        }
                    }
                }
            }

			.action { 
                display: inline-block;
                width: 100%;
				.action-holder { 
                    display: inline-block;
                    width: 100%;
					.submit { 
                        display: inline-block;
                        width: 100%;
						.btn { 
                            display: inline-block;
                            width: 100%;
                            text-align: center;
						}
					}
				}
			}
		}
	}
}

.catalog-filter-mobile {
    display: inline-block;
    width: 100%;
    .catalog-filter-mobile-holder {
        display: inline-block;
        width: 100%;
        .btn {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 13px 14px;
            .text {
                float: left;
            }

            .icon {
                float: left;
                width: 16px;
                height: 17px;
                margin-left: 5px;
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/filter";
@import "../../media/mobile/includes/catalog/filter";
