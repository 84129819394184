.services-list { 
    display: inline-block;
    width: 100%;
    margin: 0 0 73px;
	.services-list-holder { 
        display: inline-block;
        width: 100%;
        position: relative;
        .services-background {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            z-index: 1;
            img {
                width: 784px;
                height: 479px;
                object-fit: contain;
            }
        }

		.services-categories { 
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 30px);
            margin-left: -15px;
            position: relative;
            box-sizing: border-box;
            padding-right: 51%;
            z-index: 2;
			.category-col { 
                width: 50%;
                box-sizing: border-box;
                padding: 0 15px;
                margin: 0 0 32px;
				.category-col-holder { 
                    display: inline-block;
                    width: 100%;
					.heading { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 13px;
						a { 
                            color: $gray-base;
                            &:hover {
                                color: $brand-primary;
                            }
						}
					}

					.services-links { 
                        display: inline-block;
                        width: 100%;
						ul { 
                            display: inline-block;
                            width: 100%;
                            list-style: none;
                            padding: 0;
                            margin: 0;
							li { 
                                display: inline-block;
                                width: 100%;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 140%;
                                margin: 0 0 3px;
                                &:last-of-type {
                                    margin: 0;
                                }
								a { 
                                    color: $gray-base;
                                    &:hover {
                                        color: $brand-primary;
                                    }
								}
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/services_list";
@import "../../media/mobile/includes/catalog/services_list";
