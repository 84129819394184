@media (max-width: $screen-sm) {
    // sm-view
    .text-block { 
        .text-block-holder {
            padding: 40px 0 40px;
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
