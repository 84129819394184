@media (max-width: $screen-sm) {
    // sm-view
    .models-list-block { 
        padding: 25px 0 40px;
        .models-list-block-holder { 
            .heading {
                margin: 0 0 29px;
            }

            .models-list.scrollabale-xs { 
                overflow-y: scroll;
                width: 110.35%;
                margin-left: -5.175%;
                box-sizing: border-box;
                padding: 0 5.175%;
                scrollbar-width: none !important;
                -ms-overflow-style: none !important;
                &::-webkit-scrollbar {
                    display: none !important;
                }
                &.tighter {
                    .models-list-holder { 
                        width: 894px;
                    }
                }
                .models-list-holder { 
                    column-count: 6;
                    width: 1236px;
                }
            }
            

            .models-list { 
                &.xs-6-col {
                    .models-list-holder { 
                        column-count: 6;
                    }
                }
                &.xs-2-col {
                    .models-list-holder { 
                        column-count: 2;
                        column-gap: 14px;
                    }
                }
                &.tighter {
                    .models-list-holder { 
                        .model-item { 
                            margin: 0 0 10px;
                            .model-item-holder {
                                padding-left: 28px; 
                                .name {
                                    a {
                                        font-size: 10px;
                                        .name {
                                            
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .models-list-holder { 
                    .model-item { 
                        margin: 0 0 15px;
                        .model-item-holder { 
                            padding-left: 23px;
                            .letter { 
                                font-size: 18px;
                                line-height: 120%;
                                top: 4px;
                            }

                            .name { 
                                a {
                                    font-size: 14px;
                                    .icon {
                                        margin-right: 10px;
                                        img {
                                            width: 30px;
                                            height: 30px;
                                        }
                                    }

                                    .name {
                                        width: calc(100% - 30px - 10px);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .action { 
                width: 100%;
                margin: 12px 0 0;
                .btn {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}