@media (max-width: $screen-lg) {
    // lg-md view
    .models-list-block {
        padding: 30px 0 58px; 
        .models-list-block-holder { 
            .heading { 

            }

            .models-list { 
                &.lg-4-col {
                    .models-list-holder { 
                        column-count: 4;
                    }
                }
                &.lg-6-col {
                    .models-list-holder { 
                        column-count: 6;
                    }
                }
                .models-list-holder { 
                    column-count: 3;
                    column-gap: 21px;
                    .model-item {
                        .model-item-holder { 

                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .models-list-block {
        padding: 25px 0 50px;
        .models-list-block-holder { 
            .heading { 
                margin: 0 0 31px;
            }

            .models-list { 
                &.md-3-col {
                    .models-list-holder { 
                        column-count: 3;
                    }
                }
                &.md-6-col {
                    .models-list-holder { 
                        column-count: 6;
                    }
                }
                .models-list-holder { 
                    .model-item { 
                        .model-item-holder { 
                            .letter {

                            }

                            .name {
                                a {
                                    font-size: 14px;
                                    .icon {

                                    }

                                    .nmae {

                                    }
                                }
                            }
                        }
                    }
                }
            }

            .action { 
                margin: 10px 0 0;
                .btn {
                    padding: 14px 42px;
                }
            }
        }
    }
}
