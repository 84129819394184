@media (max-width: $screen-sm) {
    // sm-view
    .items-list {
        margin: 0 0 20px;
        .items-list-holder {
            .catalog-item {
                margin: 0 0 26px;
            }
        }
    }

    .catalog-item { 
        .catalog-item-holder { 
            border: 1px solid $gray-lighter;
            box-sizing: border-box;
            padding: 20px 20px 0;
            .image {
                width: 100%;
                height: 50vw;
                margin: 0 0 18px;
            }

            .about-item { 
                width: 100%;
                padding: 0;
                .about-item-holder {
                    .item-desc { 
                        width: 100%;
                        padding: 0;
                        margin: 0 0 17px;
                        .item-desc-holder { 
                            >.name { 
                                a {

                                }
                            }

                            .waranty { 
                                .value { 

                                }
                            }

                            .parameters { 
                                .parameters-holder { 
                                    flex-wrap: wrap;
                                    .param { 
                                        width: 50%;
                                        margin: 0 0 14px;
                                        .param-holder { 
                                            font-size: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .item-order { 
                        width: 100%;
                        .item-order-holder { 
                            .price { 
                                text-align: left;
                                margin: 0 0 20px;
                                .value {

                                }

                                .add-price { 
                                    font-size: 10px;
                                    margin: 3px 0 0;
                                }
                            }

                            .action {
                                width: calc(100% + 40px);
                                margin-left: -20px; 
                                .btn {
                                    background-color: $brand-primary;
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
