.glass-type-select { 
    display: inline-block;
    width: 100%;
    margin: 0 0 26px;
	.glass-type-select-holder { 
        display: inline-block;
        width: 100%;
		.glass-types { 
            width: calc(94.5% + 15px);
            margin-left: -7.5px;
			.type { 
                float: left;
                width: 20%;
                box-sizing: border-box;
                padding: 0 7.5px;
				.type-holder { 
                    display: inline-block;
                    width: 100%;
					a { 
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 60px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 140%;
                        background-color: $bg-secondary;
                        color: $gray-base;
                        padding: 20px 20px;
                        box-sizing: border-box;
                        &:hover {
                            color: $brand-primary;
                        }
						.icon { 
                            float: left;
                            width: 30px;
                            height: 30px;
                            color: $brand-primary;
							svg { 

							}
						}

						.text { 
                            width: calc(100% - 30px);
                            box-sizing: border-box;
                            padding-left: 15px;
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/glass_type";
@import "../../media/mobile/includes/catalog/glass_type";
