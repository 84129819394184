@media (max-width: $screen-lg) {
    // lg-md view
    .text-block { 
        .text-block-holder {
            padding: 44px 0 44px;
            width: 88%;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .text-block { 
        .text-block-holder {
            padding: 28px 0 28px;
            width: 100%;
        }
    }
}
