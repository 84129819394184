@media (max-width: $screen-lg) {
    // lg-md view
    .additional-services-block { 
        overflow: hidden;
        .additional-services-block-holder { 
            .heading { 
                margin: 0 0 37px;
                .controlls { 
                    
                }
            }

            .slick-slider {
                width: calc(100% + 20px);
                margin-left: -10px;
                box-sizing: border-box;
                padding-right: 3.4%;
                .slick-list {
                    overflow: visible;
                    .slick-slide {
                        padding: 0 10px;
                    }
                }
            }

            .services-slider { 

                .service-item { 
    
                    .service-item-holder { 
    
                        .image { 
    
                            a { 
    
                                img { 
    
                                }
                            }
                        }
    
                        .desc { 
    
                            .desc-holder { 
                                padding: 30px 30px 26px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .additional-services-block { 
        padding: 52px 0 49px;
        .additional-services-block-holder { 

            .slick-slider {
                width: calc(100% + 10px);
                margin-left: -5px;
                box-sizing: border-box;
                padding-right: 31%;
                .slick-list {
                    overflow: visible;
                    .slick-slide {
                        padding: 0 5px;
                    }
                }
            }

            .services-slider { 

                .service-item { 
    
                    .service-item-holder { 
    
                        .image { 
    
                            a { 
    
                                img { 
    
                                }
                            }
                        }
    
                        .desc { 
    
                            .desc-holder { 
                                padding: 25px 25px 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
