@media (max-width: $screen-sm) {
    // sm-view
    .work-examples-block { 
        margin: 0 0 57px;
        .work-examples-block-holder { 

            .slick-slider {
                padding-right: 6.8%;
            }

            .exmaples-slider { 
                .example-image {
                    
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
